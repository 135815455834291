import { DATE_FORMAT_YYYY_MM_DD } from "@/models/constants/date.constant";
import { PropsModel } from "@/models/interfaces/common.interface";
import { changeCurrencytoNumeric } from "@/validator/globalvalidator";
import moment from "moment";
import {
  REFUND_OPTIONS,
  RETURN_SUPPLIER_FROM_OPTION,
  RETURN_SUPPLIER_FROM_TYPE,
} from "../enums";
import {
  DataRequestReturnToSupplier,
  FormFinderValues,
  FormValues,
  LineRequestDTO,
  LocationDataList,
  Row,
  RowLocation,
} from "../types";

export class SupplierReturnDTOFactory {
  initLineDTO(): LineRequestDTO {
    return {
      grNumber: "",
      invoiceNumber: "",
      apLineId: "",
      grLineId: "",
      productId: "",
      uom: "",
      uomId: "",
      qty: 0,
      price: 0,
      qtyReturn: 0,
      totalPrice: 0,
      locationDataList: [],
    };
  }

  initDTO(): DataRequestReturnToSupplier {
    return {
      supplierId: "",
      grNumber: "",
      poNumber: "",
      invoiceNumber: "",
      branchId: "",
      grDate: "",
      invoiceDate: "",
      returnDate: "",
      currency: "",
      rates: 1,
      returnFrom: "",
      refundOptions: "",
      notes: "",
      lineRequestDTO: [],
    };
  }

  private transformReturnFrom(
    params: RETURN_SUPPLIER_FROM_OPTION
  ): RETURN_SUPPLIER_FROM_TYPE {
    if (params === RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT) {
      return RETURN_SUPPLIER_FROM_TYPE.GR_PRICE;
    } else {
      return RETURN_SUPPLIER_FROM_TYPE.INVOICE_AP;
    }
  }

  private buildLocationLineDTO(lines: RowLocation[]): LocationDataList[] {
    return lines
      .filter(
        item =>
          changeCurrencytoNumeric(item.qtyAvailable) > 0 &&
          changeCurrencytoNumeric(item.qtyReturn) > 0
      )
      .map<LocationDataList>(item => ({
        location: item.location,
        locationId: item.locationId,
        qtyAvailable: changeCurrencytoNumeric(item.qtyAvailable || 0),
        qtyReturn: changeCurrencytoNumeric(item.qtyReturn || 0),
      }));
  }

  buildLinesDTO(lines: Row[]): LineRequestDTO[] {
    return lines.map<LineRequestDTO>(item => ({
      grNumber: item.grNumber,
      grLineId: item.grLineId,
      apLineId: item.apLineId,
      invoiceNumber: item.invoiceNumber,
      productId: item.productId,
      uomId: item.uomId,
      qty: changeCurrencytoNumeric(item.qtyReceivedInvoiced || 0),
      price: changeCurrencytoNumeric(item.price || 0),
      qtyReturn: changeCurrencytoNumeric(item.qtyReturn || 0),
      totalPrice: changeCurrencytoNumeric(item.total || 0),
      locationDataList: this.buildLocationLineDTO(item.listLocation),
    }));
  }

  build(
    form: {
      finder: FormFinderValues;
      detail: FormValues;
      additional: PropsModel;
    },
    lines: Row[]
  ): DataRequestReturnToSupplier {
    const { finder, detail, additional } = form;
    const request: DataRequestReturnToSupplier = this.initDTO();
    request.supplierId = finder.supplierId;
    request.poNumber = detail.poNumber;
    request.branchId = additional.branchId;
    request.returnDate = moment(detail.returnDate).format(
      DATE_FORMAT_YYYY_MM_DD
    );
    request.currency = detail.currency;
    request.rates = changeCurrencytoNumeric(detail.rate || 1);
    request.returnFrom = this.transformReturnFrom(finder.returnFrom);
    request.refundOptions = detail.refundOption || REFUND_OPTIONS.NONE;
    request.notes = detail.notes || "";

    if (finder.returnFrom === RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT) {
      request.grDate = moment(detail.grDate).format(DATE_FORMAT_YYYY_MM_DD);
      request.grNumber = detail.grNumber;
    } else if (finder.returnFrom === RETURN_SUPPLIER_FROM_OPTION.INVOICE) {
      request.invoiceNumber = detail.invoiceNumber;
      request.invoiceDate = detail.invoiceDate;
    }

    request.lineRequestDTO = this.buildLinesDTO(lines).filter(
      item => item.locationDataList.length
    );

    return request;
  }
}
