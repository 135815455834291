var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_return_to_supplier") } },
        [
          !_vm.isModeEdit
            ? _c(
                "a-card-grid",
                { staticClass: "w-100", attrs: { hoverable: false } },
                [
                  _c(
                    "a-form",
                    {
                      attrs: {
                        "label-col": { span: 5 },
                        "wrapper-col": { span: 9 },
                        form: _vm.formFind
                      }
                    },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRuleFind.supplierName.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRuleFind.supplierName.decorator,
                                  expression:
                                    "formRuleFind.supplierName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRuleFind.supplierName.name,
                                placeholder: _vm.$t(
                                  _vm.formRuleFind.supplierName.placeholder
                                ),
                                allowClear: true,
                                showSearch: true,
                                disabled: _vm.disabled.supplierName,
                                loading: _vm.loading.supplierName,
                                "filter-option": false
                              },
                              on: {
                                search: function(value) {
                                  return _vm.searchDropdown(
                                    value,
                                    "supplierName"
                                  )
                                },
                                change: _vm.onChangeSupplier
                              }
                            },
                            _vm._l(_vm.listSupplierName, function(item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s("" + item.firstName) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + item.firstName) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRuleFind.returnFrom.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRuleFind.returnFrom.decorator,
                                  expression:
                                    "formRuleFind.returnFrom.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRuleFind.returnFrom.name,
                                placeholder: _vm.$t(
                                  _vm.formRuleFind.returnFrom.label
                                ),
                                disabled: _vm.disabled.returnFrom,
                                "allow-clear": false,
                                "show-search": false
                              },
                              on: { change: _vm.onChangeReturnFrom }
                            },
                            _vm._l(_vm.returnFromOption, function(item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.value } },
                                [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRuleFind.documentNumber.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value:
                                    _vm.formRuleFind.documentNumber.decorator,
                                  expression:
                                    "formRuleFind.documentNumber.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRuleFind.documentNumber.name,
                                placeholder: _vm.$t(
                                  _vm.formRuleFind.documentNumber.placeholder
                                ),
                                allowClear: true,
                                showSearch: true,
                                disabled: _vm.disabled.documentNumber,
                                loading: _vm.loading.documentNumber,
                                "filter-option": false
                              },
                              on: {
                                search: function(value) {
                                  return _vm.searchDropdown(
                                    value,
                                    "documentNumber"
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataGrOrInvoiceNumber, function(
                              item,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s("" + item.documentNumber) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s("" + item.documentNumber) +
                                          " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            "wrapper-col": {
                              xs: { span: 24 },
                              lg: { span: 12, offset: 5 }
                            }
                          }
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.disabled.find,
                                loading: _vm.loading.find,
                                icon: "search"
                              },
                              on: { click: _vm.findData }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-card-grid",
            { staticClass: "w-100", attrs: { hoverable: false } },
            [
              _c(
                "a-tabs",
                { attrs: { type: "card" } },
                [
                  _c(
                    "a-tab-pane",
                    {
                      key: "result",
                      staticClass: "bordertabs p-4",
                      attrs: { tab: _vm.$t("lbl_result") }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                {
                                  attrs: {
                                    layout: "horizontal",
                                    "label-col": { span: 10 },
                                    "wrapper-col": { span: 14 },
                                    "label-align": "left",
                                    form: _vm.formDetail
                                  }
                                },
                                [
                                  _c(
                                    "a-row",
                                    { attrs: { gutter: [16, 16] } },
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isModeCreate,
                                                  expression: "!isModeCreate"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult
                                                    .documentNumber.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .documentNumber
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.documentNumber.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .documentNumber.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult
                                                      .documentNumber
                                                      .placeholder
                                                  ),
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isModeCreate,
                                                  expression: "!isModeCreate"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.returnFrom
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .returnFrom.decorator,
                                                    expression:
                                                      "formRuleResult.returnFrom.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .returnFrom.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult
                                                      .returnFrom.placeholder
                                                  ),
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult
                                                    .supplierName.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .supplierName.decorator,
                                                    expression:
                                                      "formRuleResult.supplierName.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .supplierName.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult
                                                      .supplierName.placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabled
                                                      .supplierNameResult
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.grNumber,
                                                  expression: "show.grNumber"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.grNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .grNumber.decorator,
                                                    expression:
                                                      "formRuleResult.grNumber.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.grNumber
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.grNumber
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabled.grNumber
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.grDate,
                                                  expression: "show.grDate"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.poNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .poNumber.decorator,
                                                    expression:
                                                      "formRuleResult.poNumber.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.poNumber
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.poNumber
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabled.poNumber
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.invoiceNumber,
                                                  expression:
                                                    "show.invoiceNumber"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult
                                                    .invoiceNumber.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .invoiceNumber
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.invoiceNumber.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .invoiceNumber.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult
                                                      .invoiceNumber.placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabled.invoiceNumber
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.branch
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult.branch
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.branch.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.branch
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.branch
                                                      .placeholder
                                                  ),
                                                  disabled: _vm.disabled.branch
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.currency
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .currency.decorator,
                                                    expression:
                                                      "formRuleResult.currency.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.currency
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.currency
                                                      .placeholder
                                                  ),
                                                  disabled:
                                                    _vm.disabled.currency
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.rate.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult.rate
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.rate.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.rate
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.rate
                                                      .placeholder
                                                  ),
                                                  disabled: _vm.disabled.rate
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.grDate,
                                                  expression: "show.grDate"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.grDate
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-input", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult.grDate
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.grDate.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.grDate
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.grDate
                                                      .placeholder
                                                  ),
                                                  disabled: _vm.disabled.grDate
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.invoiceDate,
                                                  expression: "show.invoiceDate"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.invoiceDate
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .invoiceDate.decorator,
                                                    expression:
                                                      "formRuleResult.invoiceDate.decorator"
                                                  }
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .invoiceDate.name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult
                                                      .invoiceDate.placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  disabled:
                                                    _vm.disabled.invoiceDate
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.returnDate
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult
                                                        .returnDate.decorator,
                                                    expression:
                                                      "formRuleResult.returnDate.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult
                                                      .returnDate.name,
                                                  placeholder:
                                                    _vm.formRuleResult
                                                      .returnDate.placeholder,
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT,
                                                  disabled: true
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult.notes.label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-textarea", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRuleResult.notes
                                                        .decorator,
                                                    expression:
                                                      "formRuleResult.notes.decorator"
                                                  }
                                                ],
                                                attrs: {
                                                  name:
                                                    _vm.formRuleResult.notes
                                                      .name,
                                                  placeholder: _vm.$t(
                                                    _vm.formRuleResult.notes
                                                      .placeholder
                                                  ),
                                                  disabled: _vm.disabled.notes,
                                                  row: 4
                                                }
                                              }),
                                              _c("character-length", {
                                                attrs: {
                                                  value: _vm.formDetail.getFieldValue(
                                                    "notes"
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.mode !== "create",
                                                  expression:
                                                    "mode !== 'create'"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult
                                                    .journalNumber.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-button",
                                                {
                                                  attrs: { type: "link" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.clickJournal(
                                                        _vm.journalNumber.id
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.journalNumber.name
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-item",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.show.refundOption,
                                                  expression:
                                                    "show.refundOption"
                                                }
                                              ],
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRuleResult
                                                    .refundOption.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-radio-group",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRuleResult
                                                          .refundOption
                                                          .decorator,
                                                      expression:
                                                        "formRuleResult.refundOption.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name:
                                                      _vm.formRuleResult
                                                        .refundOption.label,
                                                    disabled:
                                                      _vm.disabled.refundOption
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.refundOptions,
                                                  function(item, idx) {
                                                    return _c(
                                                      "a-radio",
                                                      {
                                                        key: idx,
                                                        attrs: {
                                                          value: item.value
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(item.label)
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-row",
                                {
                                  attrs: {
                                    gutter: [16, 16],
                                    type: "flex",
                                    justify: "space-between",
                                    align: "middle"
                                  }
                                },
                                [
                                  _c("a-col", [
                                    _c("h3", [_vm._v("Items Details")])
                                  ]),
                                  _c(
                                    "a-col",
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "grey" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_total_found")
                                              ) +
                                              " : " +
                                              _vm._s(_vm.dataListItems.length) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c("TableCustom", {
                                        attrs: {
                                          idTable: "tableSupplierReturn",
                                          scroll: { x: 1000 },
                                          dataSource: _vm.dataListItems,
                                          columns: _vm.columnsTable,
                                          paginationcustom: false,
                                          defaultPagination: true,
                                          handleInput: _vm.handleInput
                                        },
                                        on: { "on-addData": _vm.handleAddData }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24, align: "end" } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c("a-button", { on: { click: _vm.back } }, [
                                    _vm._v(_vm._s(_vm.$t("lbl_back")))
                                  ]),
                                  _vm.$can("create", "return-to-supplier")
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            loading: _vm.loading.save,
                                            disabled: _vm.disabled.save
                                          },
                                          on: { click: _vm.validateForm }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("lbl_save")))]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 800,
            title: "View/Add Data",
            showmodal: _vm.modalOpen,
            idmodal: "modal1"
          }
        },
        [
          _c("TableCustom", {
            attrs: {
              slot: "contentmodal",
              idtable: "table2",
              dataSource: _vm.dataListItemsModal,
              columns: _vm.columnsTableModal,
              scroll: { x: "calc(100px + 50%)", y: 350 },
              paginationcustom: false,
              defaultPagination: true,
              handleInput: _vm.handleInputModal
            },
            slot: "contentmodal"
          }),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: { slot: "footermodal", type: "primary" },
              on: { click: _vm.saveDataModal },
              slot: "footermodal"
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }