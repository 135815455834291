




























































































































































































































































































































































































import type { TableEventAddData } from "@/components/custom/table/table-custom.type";
import { debounce } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { PropsModel } from "@/models/interfaces/common.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceAPServices } from "@/services/invoiceAp.service";
import { logisticServices } from "@/services/logistic.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { returnToSupplierServices } from "@/views/purchasing/supplier-return/services/SupplierReturn.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { RETURN_SUPPLIER_FROM_TYPE } from "./enums";
import {
  REFUND_OPTIONS,
  RETURN_SUPPLIER_FROM_OPTION,
} from "./enums/supplier-return.enum";
import { SupplierReturnFormVM } from "./SupplierReturnFormVM";
import type { FormFinderValues, FormValues, Row, RowLocation } from "./types";

@Component
export default class SupplierReturnFormView extends Mixins(MNotificationVue) {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  vm!: SupplierReturnFormVM;

  docId = "";
  mode = "" as string;
  calculationData = 0 as number;
  journalNumber = {
    id: "" as string,
    name: "" as string,
  };

  disabled = {
    notes: false,
    supplierName: false as boolean,
    returnFrom: false as boolean,
    documentNumber: false as boolean,
    grNumber: true as boolean,
    poNumber: true as boolean,
    invoiceNumber: true as boolean,
    branch: true as boolean,
    grDate: true as boolean,
    returnDate: false as boolean,
    journalNumber: false as boolean,
    currency: true as boolean,
    rate: true as boolean,
    refundOption: false as boolean,
    save: false as boolean,
    supplierNameResult: true as boolean,
    find: false as boolean,
    invoiceDate: false,
  };
  modalOpen = false as boolean;
  listSupplierName = [] as ContactData[];
  loading = {
    supplierName: false as boolean,
    save: false as boolean,
    documentNumber: false as boolean,
    find: false as boolean,
  };
  dataListItemsModal = [] as RowLocation[];
  columnsTableModal = [
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      width: 150,
      scopedSlots: { customRender: "isNull" },
      responsiveColInput: [
        {
          name: "qtyReturn",
          placeholder: "QTY Return",
          style: "width: 100%;",
        },
      ],
    },
    {
      title: "QTY Available",
      dataIndex: "qtyAvailable",
      key: "qtyAvailable",
      width: 150,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: "QTY Return",
      dataIndex: "qtyReturn",
      key: "qtyReturn",
      width: 150,
      scopedSlots: { customRender: "qtyReturn" },
    },
  ] as ColumnTableCustom[];

  columnsTable = [
    {
      title: this.$t("lbl_good_receipt_number"),
      dataIndex: "grNumber",
      key: "grNumber",
      // width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      // width: 300,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_qty_received_invoiced"),
      dataIndex: "qtyReceivedInvoiced",
      key: "qtyReceivedInvoiced",
      // width: 300,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_available_return"),
      dataIndex: "qtyAvailableReturn",
      key: "qtyAvailableReturn",
      // width: 300,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_price"),
      dataIndex: "price",
      key: "price",
      // width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_qty_return"),
      dataIndex: "qtyReturn",
      key: "qtyReturn",
      // width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      key: "total",
      // width: 200,
      scopedSlots: { customRender: "isCurrency" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      key: "location",
      // width: 100,
      scopedSlots: { customRender: "buttonAdd" },
    },
  ] as ColumnTableCustom[];
  dataListItems: Row[] = [];
  dataDetailFind = {
    branchId: "",
  };
  show = {
    invoiceNumber: false,
    invoiceDate: false,
    refundOption: false,
    poNumber: false,
    grDate: false,
    grNumber: false,
  };
  dataGrOrInvoiceNumber = [] as any[];
  formFind!: WrappedFormUtils;
  formDetail!: WrappedFormUtils;
  formRuleFind = {
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierId",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierId",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    returnFrom: {
      label: "lbl_return_from",
      name: "returnFrom",
      placeholder: "lbl_return_from",
      decorator: ["returnFrom"],
    },
    documentNumber: {
      label: "lbl_document_number",
      name: "documentNumber",
      placeholder: "lbl_document_number_placeholder",
      decorator: [
        "documentNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  formRuleResult = {
    documentNumber: {
      label: "lbl_supplier_return_number",
      name: "documentNo",
      placeholder: "lbl_document_number",
      decorator: ["documentNo"],
    },
    notes: {
      label: "lbl_notes",
      name: "notes",
      placeholder: "lbl_notes",
      decorator: ["notes"],
    },
    returnFrom: {
      label: "lbl_return_from",
      name: "returnFrom",
      placeholder: "lbl_return_from",
      decorator: ["returnFrom"],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name",
      decorator: ["supplierName"],
    },
    grNumber: {
      label: "lbl_good_receipt_number",
      name: "grNumber",
      placeholder: "lbl_good_receipt_number",
      decorator: ["grNumber"],
    },
    poNumber: {
      label: "lbl_purchase_order_number",
      name: "poNumber",
      placeholder: "lbl_purchase_order_number",
      decorator: ["poNumber"],
    },
    grDate: {
      label: "lbl_good_receipt_date",
      name: "grDate",
      placeholder: "lbl_good_receipt_date",
      decorator: ["grDate"],
    },
    journalNumber: {
      label: "lbl_journal_number",
      name: "journalNumber",
      placeholder: "lbl_journal_number",
      decorator: ["journalNumber"],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency",
      decorator: ["currency"],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate",
      decorator: ["rate"],
    },
    returnDate: {
      label: "lbl_return_date",
      name: "returndate",
      placeholder: DEFAULT_DATE_FORMAT,
      decorator: [
        "returnDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
          initialValue: moment(),
        },
      ],
    },
    invoiceNumber: {
      label: "lbl_invoice_number",
      name: "invoicenumber",
      placeholder: "lbl_invoice_number",
      decorator: ["invoiceNumber"],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: ["branch"],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date",
      decorator: ["invoiceDate"],
    },
    refundOption: {
      label: "lbl_refund_option",
      name: "refundOption",
      decorator: [
        "refundOption",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  returnFromOption: OptionModel[] = [
    {
      label: "lbl_good_receipt",
      value: RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT,
    },
    {
      label: "lbl_invoice",
      value: RETURN_SUPPLIER_FROM_OPTION.INVOICE,
    },
  ];

  refundOptions: OptionModel[] = [
    {
      label: "lbl_refund",
      value: REFUND_OPTIONS.REFUND,
    },
    {
      label: "lbl_debit_memo",
      value: REFUND_OPTIONS.DEBIT_MEMO,
    },
  ];

  beforeCreate(): void {
    this.formFind = this.$form.createForm(this, { name: "formFind" });
    this.formDetail = this.$form.createForm(this, { name: "formDetail" });
  }

  created(): void {
    this.vm = new SupplierReturnFormVM();

    this.mode = this.$route.meta.mode;
    this.docId = this.$route.params.id ?? "";
  }

  mounted(): void {
    this.getListSupplier("");
    if (this.mode === Mode.EDIT) this.getDetailData(this.docId);
  }

  get isModeCreate(): boolean {
    return this.mode === Mode.CREATE;
  }

  get isModeEdit(): boolean {
    return this.mode === Mode.EDIT;
  }

  @Watch("calculationData")
  checkCalculationData(value) {
    if (value > 0) {
      this.dataListItems.forEach(item => {
        item.qtyReturn = currencyFormat(
          item.listLocation.reduce(
            (n, { qtyReturn }) => n + changeCurrencytoNumeric(qtyReturn),
            0
          )
        );
        item.total = currencyFormat(
          changeCurrencytoNumeric(item.qtyReturn) *
            changeCurrencytoNumeric(item.price)
        );
      });
    }
  }

  handleInputModal(
    value,
    key,
    objectColInput,
    _objectColInputName: string,
    onEvt: string
  ) {
    let formatedVal: string | number = value;

    if (onEvt === "onBlur") {
      formatedVal = currencyFormat(value);
      this.calculationData += 1;
    } else if (onEvt === "onFocus") {
      formatedVal = changeCurrencytoNumeric(value);
    }
    this.dataListItemsModal[key][objectColInput.name] = formatedVal;
    this.dataListItemsModal = this.dataListItemsModal.slice();
  }

  /**
   * @description find inventory by product id and assign into
   * list location
   * @param key index row to assign location
   * @param productId product id
   */
  getListOfInventoryLine(key: number, productId: string): void {
    const params = new RequestQueryParams("available>0");
    if (productId) {
      params.search += `_AND_product.secureId~${productId}`;
    }
    this.dataListItemsModal = [];
    logisticServices.listOfInventory(params).then(res => {
      const dataLocation = res.data.map<RowLocation>((item, index) => ({
        key: index,
        location: item.warehouseLocationName ?? "",
        locationId: item.warehouseLocationId ?? "",
        qtyAvailable: currencyFormat(item.available || 0),
        qtyReturn: "",
      }));
      this.dataListItemsModal = dataLocation;
      this.dataListItems[key].listLocation = this.dataListItemsModal;
    });
  }

  handleAddData(response: TableEventAddData<Row>): void {
    this.dataListItemsModal =
      this.dataListItems[response.data.key as number].listLocation;
    this.modalOpen = true;
  }

  saveDataModal() {
    this.modalOpen = false;
  }

  getListSupplier(value: string): void {
    const params = new RequestQueryParams();
    params.search = `supplier~true_AND_active~true`;
    if (value) {
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.supplierName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.listSupplierName = res.data;
      })
      .finally(() => (this.loading.supplierName = false));
  }

  /**
   * @description handler search fields
   * @param value search value
   * @param type which fields
   */
  searchDropdown(value: string, type: "supplierName" | "documentNumber"): void {
    debounce(() => {
      if (type === "supplierName") {
        this.getListSupplier(value);
      } else {
        this.changeDataReturnForm(value);
      }
    });
  }

  getInvoice(value: string | null): void {
    const params = new RequestQueryParams();
    params.sorts = "createdDate:desc";
    params.supplierId = this.formFind.getFieldValue("supplierId");
    if (value) {
      params.search = value;
    }
    this.loading.documentNumber = true;
    invoiceAPServices
      .listApInvoiceAvailable(params)
      .then(res => {
        const arrayUniqueByKey = [
          ...new Map(res.map(item => [item.documentNumber, item])).values(),
        ];
        this.dataGrOrInvoiceNumber = arrayUniqueByKey;
      })
      .finally(() => (this.loading.documentNumber = false));
  }

  getGr(value: string | null): void {
    const params = new RequestQueryParams();
    params.status = "UN_BILLED";

    if (this.formFind.getFieldValue("supplierId")) {
      params.supplierId = this.formFind.getFieldValue("supplierId");
    }
    if (value) params.receiveNumber = value;
    this.loading.documentNumber = true;
    returnToSupplierServices
      .getListGrReturnToSupplier(params)
      .then(res => {
        res.data.forEach(element => {
          element["documentNumber"] = element.receiveNumber;
        });
        this.dataGrOrInvoiceNumber = res.data;
      })
      .finally(() => (this.loading.documentNumber = false));
  }

  onChangeSupplier(value: string | null): void {
    this.formFind.setFieldsValue({
      supplierId: value,
    });
    if (!value) return;
    this.findDocs();
  }

  onChangeReturnFrom(value: string | null): void {
    this.formFind.setFieldsValue({
      returnFrom: value,
    });
    if (!value) return;
    this.findDocs();
  }

  /**
   * @description handler dropdown changes
   * @param value changed value
   */
  findDocs(): void {
    this.formFind.setFieldsValue({
      documentNumber: "",
    });

    // get latest value return from
    const returnFrom = this.formFind.getFieldValue("returnFrom");

    if (returnFrom === RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT) {
      this.show.refundOption = false;
      this.show.invoiceNumber = false;
      this.show.invoiceDate = false;
      this.show.grDate = true;
      this.show.poNumber = true;
      this.show.grNumber = true;
      this.toggleRequiredRefundOption(false);
    } else if (returnFrom === RETURN_SUPPLIER_FROM_OPTION.INVOICE) {
      this.show.refundOption = true;
      this.show.invoiceNumber = true;
      this.show.invoiceDate = true;
      this.show.grDate = false;
      this.show.poNumber = false;
      this.show.grNumber = false;
      this.toggleRequiredRefundOption(true);
    }

    this.changeDataReturnForm(null);
  }

  toggleRequiredRefundOption(value: boolean): void {
    const [_, rule] = this.formRuleResult.refundOption.decorator as any;
    rule.rules[0].required = value;
  }

  changeDataReturnForm(value: string | null): void {
    const { returnFrom } = this.formFind.getFieldsValue();
    if (returnFrom === RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT) {
      this.getGr(value);
    } else {
      this.getInvoice(value);
    }
  }

  /**
   * @description find document data based on type
   * @param value document number
   */
  detailData(value: string): void {
    const returnFrom: RETURN_SUPPLIER_FROM_OPTION =
      this.formFind.getFieldValue("returnFrom");
    if (returnFrom === RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT) {
      this.detailGr(value);
    } else {
      this.detailInvoice(value);
    }
  }

  clickJournal(id) {
    if (id) this.$router.push("/generaljournal/journal/detail/" + id);
  }

  /**
   * @description find document GR based on document number
   * @param value document number
   */
  detailGr(value: string): void {
    this.loading.find = true;
    returnToSupplierServices
      .getDetailGrReturnToSupplier({}, value)
      .then(res => {
        this.journalNumber.id = res.journalNumberId as string;
        this.journalNumber.name = res.journalNo as string;
        this.dataDetailFind.branchId = res.branchWarehouseId as string;
        this.formDetail.setFieldsValue({
          supplierName: res.supplierName,
          grNumber: res.receiveNumber,
          poNumber: res.purchaseOrderNumber,
          grDate: res.receiveDate
            ? moment(res.receiveDate).format(DEFAULT_DATE_FORMAT)
            : null,
          branch: res.branchWarehouse,
          currency: res.currencyCode,
          rate: currencyFormat(res.currencyRate || 1),
        });
        res.receiveItems.forEach((item, index) => {
          this.dataListItems.push({
            key: index,
            productId: item.productId,
            productCode: item.productCode,
            uom: item.produtUom,
            uomId: item.productUomId,
            grNumber: res.receiveNumber as string,
            grLineId: item.id as string,
            qtyReceivedInvoiced: item.qty ? currencyFormat(item.qty) : "",
            price: item.price ? currencyFormat(item.price) : "",
            qtyReturn: "",
            total: "",
            location: "",
            listLocation: [],
            isClick: false,
            qtyAvailableReturn: item.qtyAvailableForReturn || 0,
            apLineId: "",
            invoiceNumber: "",
          });
          this.getListOfInventoryLine(index, item.productId);
        });
      })
      .finally(() => (this.loading.find = false));
  }

  /**
   * @description find available AP invoice for return
   * @param value document number
   */
  detailInvoice(value: string): void {
    this.loading.find = true;
    invoiceAPServices
      .detailApInvoiceReturn(value)
      .then(res => {
        this.journalNumber.id = res.journalId;
        this.journalNumber.name = res.journalNo;
        this.dataDetailFind.branchId = res.branchWarehouseId;
        this.formDetail.setFieldsValue({
          supplierName: res.supplierName,
          branch: res.branchWarehouseName,
          currency: res.currency,
          invoiceNumber: res.documentNumber,
          invoiceDate: res.invoiceDate
            ? moment(res.invoiceDate).format()
            : null,
          rate: currencyFormat(res.currencyRate || 1),
        });
        res.invoiceAPLines.forEach((item, index) => {
          this.dataListItems.push({
            key: index,
            productId: item.productId,
            productCode: item.partNumber,
            uom: item.uom,
            uomId: item.uomId,
            grNumber: "",
            grLineId: "",
            qtyReceivedInvoiced: item.qty ? currencyFormat(item.qty) : "",
            price: item.price ? currencyFormat(item.price) : "",
            qtyReturn: "",
            total: "",
            location: "",
            listLocation: [],
            isClick: false,
            qtyAvailableReturn: item.qtyAvailableForReturn || 0,
            apLineId: item.id || "",
            invoiceNumber: item.documentReference,
          });
          this.getListOfInventoryLine(index, item.productId);
        });
      })
      .finally(() => (this.loading.find = false));
  }

  /**
   * @description find data based on form finder
   */
  findData(): void {
    this.formFind.validateFields((err, value) => {
      if (err) return;
      this.dataDetailFind.branchId = "";
      this.dataListItems = [];
      this.detailData(value.documentNumber);
    });
  }

  /**
   * @description get detail document
   * @param id document secure id
   */
  getDetailData(id: string): void {
    returnToSupplierServices.getDetailReturnToSupplier(id).then(res => {
      for (const key in this.disabled) {
        this.disabled[key] = true;
      }

      this.formDetail.setFieldsValue({
        notes: res.notes,
        supplierName: res.supplierName,
        branch: res.branchName,
        poNumber: res.poNumber,
        returnDate: moment(res.returnDate).format(),
        currency: res.currency,
        rate: currencyFormat(res.rates || 1),
        documentNo: res.documentNo,
        returnFrom:
          res.returnFrom === RETURN_SUPPLIER_FROM_TYPE.GR_PRICE
            ? RETURN_SUPPLIER_FROM_OPTION.GOOD_RECEIPT
            : RETURN_SUPPLIER_FROM_OPTION.INVOICE,
      });
      this.journalNumber.id = res.journalNumberId;
      this.journalNumber.name = res.journalNumber;
      res.detailLines.forEach((item, index) => {
        this.dataListItemsModal = [];
        item.locationDataList.forEach((data, i) => {
          data["key"] = i;
          data["qtyReturn"] = data.qtyReturn
            ? currencyFormat(data.qtyReturn)
            : "";
          data["isClick"] = false;
        });
        this.dataListItemsModal = item.locationDataList as [];
        this.columnsTableModal[0].responsiveColInput[0].disabled = true;

        const qtyAvailableReturn: number = new Decimal(item.qty || 0)
          .minus(item.qtyReturn || 0)
          .toNumber();
        this.dataListItems.push({
          qtyAvailableReturn,
          key: index,
          grNumber: item.grNumber,
          apLineId: item.apLineId,
          invoiceNumber: item.invoiceNumber || "",
          grLineId: item.grLineId,
          productCode: item.productCode,
          uom: item.uom,
          uomId: item.uomId,
          productId: item.productId,
          disableByRow: ["qtyReturn"],
          qtyReceivedInvoiced: item.qty ? currencyFormat(item.qty) : "",
          price: item.price ? currencyFormat(item.price) : "",
          qtyReturn: item.qtyReturn ? currencyFormat(item.qtyReturn) : "",
          total: item.totalPrice ? currencyFormat(item.totalPrice) : "",
          location: "",
          listLocation: this.dataListItemsModal,
          isClick: false,
        });
      });
      if (res.returnFrom === RETURN_SUPPLIER_FROM_TYPE.GR_PRICE) {
        this.show.invoiceNumber = false;
        this.show.invoiceDate = false;
        this.show.refundOption = false;
      } else if (res.returnFrom === RETURN_SUPPLIER_FROM_TYPE.INVOICE_AP) {
        this.show.invoiceNumber = true;
        this.show.invoiceDate = true;
        this.show.refundOption = true;
        this.formDetail.setFieldsValue({
          invoiceNumber: res.invoiceNumber,
          invoiceDate: moment(res.invoiceDate).format(),
        });
      }
      const data = {
        grNumber: res.grNumber,
        grDate: res.grDate,
        refundOption: res.refundOptions,
      };
      this.setDataForm(data);
    });
  }

  setDataForm(values): void {
    for (const key in values) {
      this.formDetail.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.formDetail.setFieldsValue(values);
  }

  validateForm(): void {
    this.formFind.validateFields((err, values) => {
      if (err) return;

      this.formDetail.validateFields((err, valueResult) => {
        if (err) return;

        this.save(
          {
            finder: values,
            detail: valueResult,
            additional: this.dataDetailFind,
          },
          this.dataListItems
        );
      });
    });
  }

  async save(
    params: {
      finder: FormFinderValues;
      detail: FormValues;
      additional: PropsModel;
    },
    lines: Row[]
  ): Promise<void> {
    try {
      this.loading.save = true;
      const response = await this.vm.createDocument(params, lines);
      this.showNotifSuccess("notif_create_success", {
        documentNumber: response.documentNo,
      });
      this.$router.push({ name: "purchasing.transaction.supplier-return" });
    } catch (error) {
      this.showNotifError("notif_create_fail");
    } finally {
      this.loading.save = false;
    }
  }
  back() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push({ name: "purchasing.transaction.supplier-return" });
      },
      onCancel() {
        return;
      },
    });
  }

  handleInput(
    value,
    key,
    objectColInput,
    _objectColInputName: string,
    onEvt: string
  ) {
    let formatedVal: string | number = value;

    if (onEvt === "onBlur") {
      formatedVal = currencyFormat(value);
      this.calculationData += 1;
    } else if (onEvt === "onFocus") {
      formatedVal = changeCurrencytoNumeric(value);
    }
    this.dataListItems[key][objectColInput.name] = formatedVal;
    this.dataListItems = this.dataListItems.slice();
  }
}
