import { PropsModel } from "@/models/interfaces/common.interface";
import { SupplierReturnDTOFactory } from "./factory";
import { SupplierReturnService } from "./services";
import { FormFinderValues, FormValues, Row } from "./types";
export class SupplierReturnFormVM {
  private supplierReturnService: SupplierReturnService;
  private supplierReturnDTOFactory: SupplierReturnDTOFactory;
  constructor() {
    this.supplierReturnService = new SupplierReturnService();
    this.supplierReturnDTOFactory = new SupplierReturnDTOFactory();
  }

  async createDocument(
    params: {
      finder: FormFinderValues;
      detail: FormValues;
      additional: PropsModel;
    },
    lines: Row[]
  ) {
    const request = this.supplierReturnDTOFactory.build(
      {
        detail: params.detail,
        finder: params.finder,
        additional: params.additional,
      },
      lines
    );
    return this.supplierReturnService.createReturnToSupplier(request);
  }
}
