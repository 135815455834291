export enum RETURN_SUPPLIER_FROM_TYPE {
  GR_PRICE = "GR_PRICE",
  INVOICE_AP = "INVOICE_AP",
}

export enum RETURN_SUPPLIER_FROM_OPTION {
  GOOD_RECEIPT = "Good Receipt",
  INVOICE = "Invoice",
}

export enum REFUND_OPTIONS {
  REFUND = "REFUND",
  DEBIT_MEMO = "DEBIT_MEMO",
  NONE = "NONE",
}
